<template>
    <div>
      <label class="main-label mb-3"
        >Thumbnail (Maximum 10 Images)
      </label>
      <div class="panel-upload">
        <div class="panel-upload-box upload-mobile" v-if="!isMaxImg">
          <div class="panel-bg-file-img mb-2" for="file_upload">
            <font-awesome-icon icon="plus" color="#FFB300" class="icon-add" />
            <input
              type="file"
              name="name"
              ref="input"
              accept="image/png, image/jpeg"
              id="file_upload"
              v-on:change="onFileChange"
            />
          </div>
        </div>
  
        <draggable
          :list="imageList"
          class="d-flex flex-wrap px-md-10 upload-mobile"
          :style="[
            { width: isMaxImg ? '100%' : `calc(100% - 100% / ${quantityImg})` },
          ]"
        >
          <div
            class="panel-wrap-bg-file-img d-flex"
            :style="[{ width: `calc(100% / ${quantityImg})` }]"
            v-for="(item, index) in imageList"
            :key="index"
          >
            <div
              class="panel-bg-file-img mb-2"
              v-bind:style="{ backgroundImage: 'url(' + item.image_url + ')' }"
            >
              <font-awesome-icon
                icon="times-circle"
                color="#979797"
                class="icon-delete pointer"
                @click="deleteImage(index, item.id)"
              />
            </div>
          </div>
        </draggable>
      </div>
      <ModalAlertError ref="modalAlertError" :text="modalMessage" />
      <ModalLoading ref="modalLoading" :hasClose="false" />
    </div>
  </template>
  
  <script>
  import ModalAlertError from "@/components/modal/ModalAlertError";
  import ModalLoading from "@/components/modal/ModalLoading";
  import draggable from "vuedraggable";
  import Vue from "vue";
  
  export default {
    components: {
      ModalAlertError,
      ModalLoading,
      draggable,
    },
    props: {
      dataList: {
        required: false,
        type: Array,
      },
      v: {
        required: false,
        type: Object,
      },
    },
    data() {
      return {
        file: "",
        value: "",
        type: {
          all: ["image/jpeg", "image/png", "application/pdf", ""],
          file: ["image/jpeg", "image/png", "application/pdf"],
          image: ["image/jpeg", "image/png"],
        },
        modalMessage: "",
        image: "",
        filename: "",
        images: "",
  
        dragging: false,
        // image: this.dataFile && this.dataFile.id ? this.dataFile.imgUrl : require("@/assets/images/icons/picture.png")
      };
    },
    created() {
    },
    computed: {
      imageList() {
        return this.dataList;
      },
      draggingInfo() {
        return this.dragging ? "under drag" : "";
      },
      isMaxImg() {
        if (this.imageList != null) return this.imageList.length == 10;
      },
      quantityImg() {
        if (this.$store.state.screenSize.width < 767.98) {
          return 3;
        } else if (this.isMaxImg) {
          return 7;
        } else {
          return 6;
        }
      },
    },
    methods: {
      onFileChange(e) {
        // this.errorFile = "";
        const file = e.target.files[0];
        this.filename = file.name;
        var _validFileExtensions = ["image/jpeg", "image/png"];
  
        if (e.target.files.length) {
          if (_validFileExtensions.indexOf(file.type) < 0) {
            this.$refs.input.value = "";
            this.modalMessage = `fileNotSupport`;
            this.$refs.modalAlertError.show();
          } else if (file.size > 10000000) {
            this.modalMessage = `fileIsTooLarge`;
            this.$refs.modalAlertError.show();
          } else {
            // this.image = URL.createObjectURL(file);
            this.handleChangeFileImage(file);
          }
        } else {
          this.$refs.input.value = "";
        }
      },
      handleChangeFileImage: async function (value) {
        if (value) {
          this.$refs.modalLoading.show();
          var reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onload = async () => {
            let im = new Image();
            im.src = reader.result;
            im.onload = async () => {
              if (im.width !== im.height) {
                this.$swal("Please upload image with ratio 1:1 !", {
                  icon: "info",
                });
              } else {
                var savetoDB = await this.saveImagetoDb(reader.result);
                await this.saveImage(savetoDB);
              }
              this.$refs.modalLoading.hide();
            };
          };
        } else {
          this.value = "";
        }
      },
      saveImage: async function (value) {
        if (value) {
          this.imageList.push({
            image_url: value,
            news_id: 0,
            seq: 0,
            sortOrder: 0,
            sort_order: 0,
          });
        }
      },
      saveImagetoDb: async function (img) {
        var form = {
          Base64String: img,
        };
        await this.$store.dispatch("ActionUploadImage", form);
        var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
        return data.detail;
      },
      deleteImage(index, id) {
        Vue.nextTick(() => {
          this.$emit("deleteImagesList", id);
        });
        this.imageList.splice(index, 1);
      },
    },
  };
  </script>
  
  <style scoped>
  .panel-upload {
    position: relative;
    margin-bottom: 15px;
    flex-wrap: wrap;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .panel-upload-box {
    max-width: calc(100% / 7);
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
  
  .panel-upload-box-list {
    max-width: calc(100% / 7);
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
  
  .panel-wrap-bg-file-img {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .panel-bg-file-img {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 94.2%;
    border: 2px dashed #979797;
    width: 100%;
    cursor: move;
  }
  
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
    width: 100%;
    height: 100%;
    line-height: 95px;
    position: absolute;
    opacity: 0;
  }
  
  .icon-add {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 25px;
    height: 25px;
  }
  
  .icon-delete {
    position: absolute;
    right: 1px;
    top: 1px;
    color: #707070;
  }
  
  @media (max-width: 767.98px) {
    .panel-wrap-bg-file-img {
      padding-right: 5px;
      padding-left: 5px;
    }
  
    .upload-mobile {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
  </style>
  