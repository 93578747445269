<template>
  <div class="bg-white p-2 p-lg-3">
    <div class="row">
      <div class="col-md-6 col-lg-5 col-12">
        <b-input-group class="mb-2">
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearch"
            placeholder="Search Plant ID, Branch Name"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="d-flex justify-content-center">
          <b-form-checkbox
            switch
            v-model="item.status"
            class="radio-active px-4"
            size="lg"
            :value="1"
            :unchecked-value="0"
            @change="handleChangeStatus(item.branch_id, item.status)"
          >
          </b-form-checkbox>

          <b-button
            variant="link"
            class="text-bg-primary px-1 py-0"
            @click="openModal(item)"
          >
            <font-awesome-icon
              style="transform: scale(1.2)"
              icon="sliders-h"
              title="Inventory"
            />
          </b-button>
        </div>
      </template>
    </b-table>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
    <ModalEditStock ref="modal-edit-stock" @getData="getInventory" />
  </div>
</template>
<script>
import ModalEditStock from "../../../views/pages/manageBranch/components/ModalEditStock.vue";

export default {
  components: {
    ModalEditStock,
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      id: this.$route.params.id,
      isBusy: false,
      items: [],
      fields: [
        {
          key: "plant_id",
          label: "Plant ID",
          thClass: "w-2",
        },
        {
          key: "branch_name",
          label: "Branch Name",
          thClass: "w-2",
        },

        {
          key: "branch_group",
          label: "Branch Group",
          thClass: "w-2",
        },
        {
          key: "quantity",
          label: "Quantity",
          thClass: "w-2",
        },

        {
          key: "actions",
          label: "",
          thClass: "w-1",
        },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
        product_id: Number(this.$route.params.id),
      },
      deleteImageList: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      isFetch: false,
      tabLoading: true,
      isGetProductField: false,
    };
  },
  methods: {
    openModal(items) {
      items.product_id = Number(this.id);
      this.$refs["modal-edit-stock"].show(items);
    },

    handleSearch() {
      this.getInventory();
    },

    async getInventory() {
      this.isBusy = true;
      const res = await this.axios.post(
        `/Product/GetProductInventory`,
        this.filter
      );
      this.rows = res.data.detail.count;
      this.items = res.data.detail.data;
      this.isFetch = true;
      this.isBusy = false;
    },
    pagination(val) {
      this.filter.page = val;
      this.getInventory();
    },
    handleChangeTake() {
      this.getInventory();
    },
    async handleChangeStatus(id, value) {
      const res = await this.axios.post(`/Product/SwitchInventoryStatus`, {
        ProductId: this.filter.product_id,
        BranchId: id,
        newStatus: value,
      });
    },
  },
};
</script>
