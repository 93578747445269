<template>
    <div>
      <b-modal v-model="modalShow" centered size="lg" no-close-on-backdrop no-close-on-esc>
        <template #modal-header>Select Collection</template>
        <div></div>
        <b-row>
          <b-col class="col-12">
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                id="header-search-bar"
                class="search-bar"
                @keyup.enter="handleSearch"
                placeholder="Search Collection Name"
                v-model="filter.search"
              >
              </b-form-input>
              <b-input-group-append is-text>
                <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                  <b-icon
                    stacked
                    icon="search"
                    scale="0.5"
                    variant="grey"
                  ></b-icon>
                </b-iconstack>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(select)="{ item }">
          <b-form-group class="cb-table d-flex justify-content-center m-0">
            <b-form-checkbox
              :value="item"
              :checked="selectedCollection"
              @change="selectCollectionTable(item)"
            ></b-form-checkbox> </b-form-group
        ></template>
      </b-table>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
  
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-between">
            <b-button @click.prevent="hide" class="border-btn">Cancel</b-button>
  
            <b-button
              class="submit-btn text-right"
              @click.prevent="submitSelectCollection"
              >Select Collections</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  <script>
  export default {
    components: {
      // TabCollection
    },
    props: {
      selectedItem: {
        type: Array,
      },
    },
    data() {
      return {
        modalShow: false,
        isBusy: false,
        fields: [
        {
          key: "select",
          label: "",
          thClass: "w-1",
        },
        {
          key: "name",
          label: "Name",
          thClass: "w-1",
        },
        {
          key: "total_product",
          label: "Total Product",
          thClass: "w-1",
        },
        {
          key: "description",
          label: "Description",
          thClass: "w-2",
        },],
        filter: {
          page: 1,
          take: 10,
          search: null,
        },
        rows: 0,
        pageOptions: [
          { value: 10, text: "10 / page" },
          { value: 30, text: "30 / page" },
          { value: 50, text: "50 / page" },
          { value: 100, text: "100 / page" },
        ],
        items: [],
        selectedCollection: [],
        isLoading: false,
        mapProduct: [],
      };
    },
    methods: {
      async show() {
        this.modalShow = true;
        this.getCollection();
        this.selectedCollection = [...this.selectedItem];
        
      },
      async getCollection() {
      this.isBusy = true;
      const res = await this.axios.post(
        `/productmenu/get_collection_list`,
        this.filter
      );
      this.rows = res.data.detail.count;
      this.items = res.data.detail.data; 
       this.isBusy = false;
    },
      pagination(val) {
        this.filter.page = val;
        this.getCollection();
      },
      handleChangeTake() {
        this.getCollection();
      },
      handleSearch() {
        this.getCollection();
      },
      selectCollectionTable(product) {
        const index = this.selectedCollection.findIndex(
          (item) => item.id === product.id
        );
        if (index !== -1) {
          this.selectedCollection.splice(index, 1);
        } else {
          this.selectedCollection.push(product);
        }
      },
  
      async submitSelectCollection() {
        this.filter = {
          page: 1,
          take: 10,
          search: null,
        };
        this.modalShow = false;
        this.$emit("success", this.selectedCollection);
      },
      hide() {
        this.filter = {
          page: 1,
          take: 10,
          search: null,
        };
        this.modalShow = false;
      },
    },
  };
  </script>
  