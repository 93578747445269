<template>
  <div>
    <b-modal
      v-model="modalShow"
      title="Stock Management"
      @ok="handleOk"
      ok-title="Confirm"
      centered
      footer-class="content-between"
    >
      <b-tabs fill v-model="form.edit_type">
        <b-tab title="Received">
          <div class="container-stock">
            <div class="current-stock">Current Stock : {{ form.stock }}</div>
            <InputText
              textFloat="Amount"
              placeholder="Add Amount"
              type="number"
              name="Amount"
              v-model="form.amount"
              @input="handleAmoutInput"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
            />
            <InputText
              textFloat="Available"
              placeholder="Available"
              type="number"
              name="Available"
              disabled
              v-model="form.available"
            />
            <InputTextArea
              :rows="4"
              textFloat="Additional Notes"
              placeholder="Additional Notes"
              id="note"
              v-model="form.note"
            />
          </div>
        </b-tab>
        <b-tab title="Damaged">
          <div class="container-stock">
            <div class="current-stock">Current Stock : {{ form.stock }}</div>
            <InputText
              textFloat="Amount"
              placeholder="Add Amount"
              type="number"
              name="Amount"
              v-model="form.amount"
              @input="handleAmoutInput"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
            />
            <InputText
              textFloat="Available"
              placeholder="Available"
              type="number"
              name="Available"
              disabled
              v-model="form.available"
            />
            <InputTextArea
              :rows="4"
              textFloat="Additional Notes"
              placeholder="Additional Notes"
              id="note"
              v-model="form.note"
            /></div
        ></b-tab>
        <b-tab title="Adjust">
          <div class="container-stock">
            <div class="current-stock">Current Stock : {{ form.stock }}</div>
            <InputText
              textFloat="Amount"
              placeholder="Add Amount"
              type="number"
              name="Amount"
              v-model="form.amount"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
            />
            <InputTextArea
              :rows="4"
              textFloat="Additional Notes"
              placeholder="Additional Notes"
              id="note"
              v-model="form.note"
            /></div
        ></b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  minValue,
  minLength,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      modalShow: false,
      form: {
        inventory_id: 0,
        branch_id: Number(this.$route.params.id),
        product_id: 0,
        stock: 0,
        amount: "",
        edit_type: 0,
        note: "",
        available: 0,
      },
      tabsIndex: 1,
    };
  },
  validations: {
    form: {
      amount: { required },
    },
  },
  watch: {
    "form.edit_type"() {
      this.$v.form.$reset();
      return (this.form.amount = ""), (this.form.available = this.form.stock);
    },
  },
  methods: {
    handleAmoutInput() {
      if (this.form.edit_type == 0) {
        let amount = this.form.amount ? this.form.amount : 0;
        this.form.available = Number(amount) + Number(this.form.stock);
      } else {
        let amount = this.form.amount ? this.form.amount : 0;
        this.form.available = Number(this.form.stock) - Number(amount);
      }
    },
    async handleOk(e) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return e.preventDefault();
      }
      this.form.amount = Number(this.form.amount);
      this.form.edit_type += 1;
      this.$bus.$emit("showLoading");
      const response = await this.axios.post(
        `/Inventory/UpdateInventory`,
        this.form
      );

      if (response.data.result) this.successAlert();
      else this.errorAlert(response.data.message);

      this.$emit("getData");
      this.$bus.$emit("hideLoading");
    },
    show(items) {
      this.form.edit_type = 0;
      this.form.stock = items.stock
        ? items.stock
        : items.quantity
        ? items.quantity
        : 0;
      this.form.available = items.stock
        ? items.stock
        : items.quantity
        ? items.quantity
        : 0;
      this.form.inventory_id = items.id;
      this.form.product_id = items.product_id;
      this.form.note = items.note || "";
      this.form.branch_id = items.branch_id || Number(this.$route.params.id);
      this.modalShow = true;
    },
    hide() {
      this.modalShow = false;
    },
  },
};
</script>

<style scoped>
::v-deep .nav.nav-tabs.nav-fill {
  border: 10px solid #c5c5c5;
}
::v-deep .modal-body {
  padding: 0;
}
::v-deep .nav-tabs .nav-link.active,
::v-deep .nav-tabs .nav-item.show .nav-link {
  background-color: var(--primary-color);
  border-bottom: none;
  color: #fff !important;
}
::v-deep .nav-tabs .nav-item .nav-item:last-child {
  padding-right: 0px;
}

.container-stock {
  position: relative;
  padding: 1.5rem;
}
.current-stock {
  color: rgb(146, 146, 146);
  position: absolute;
  top: calc(0px+ 1.5rem);
  right: calc(0px + 1.5rem);
}
</style>
