<template>
  <div class="bg-white p-2 p-lg-3">
    <b-row class="row">
      <b-col class="col-md-6 col-10">
        <b-input-group class="mb-2">
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearch"
            placeholder="Search Name"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col class="col-md-6 d-flex justify-content-end">
        <div class="text-right mb-2">
          <b-button class="btn-filter mr-2" @click="openModalSelect()">
            <font-awesome-icon icon="plus" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="plus" class="pointer" />
              <span class="btn-text">Select Collection</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(detail)="{ item }">
        <div class="name-link" @click="openCollectionDetail(item.id)">
          Detail
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
      <template v-slot:cell(action)="{ item }">
            <div class="d-flex justify-content-center">
              <b-button
                variant="link"
                class="text-warning px-4 py-0"
                @click="openCollectionDetail(item.id)"
              >
                <font-awesome-icon icon="pencil-alt" title="Detail" />
              </b-button>

              <div class="" @click="openModalDelete(item.id)">
                <b-icon size="xl" icon="trash-fill" class="icon-size pointer" />
              </div>
            </div>
          </template>
      <template v-slot:cell(created_time)="data">
            <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.created_time | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(description)="data">
            <div v-if="data.item.description">{{ data.item.description }}</div>
            <div v-else >
            -
            </div>
          </template>
    </b-table>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
    <ModalSelectCollection
      ref="modalSelectCollection"
      :selectedItem="selectedItem"
      @success="handleSelectCollection"
    ></ModalSelectCollection>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import ModalSelectCollection from "./ModalSelectCollection.vue";


export default {
  components: { ModalSelectCollection,  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        excel_file: {
          required,
        },
      },
    };
  },
  data() {
    return {
      form: {
        email: "",
        file_name: "",
        excel_file: "",
        user_guid: "",
      },
      currnet_collection: "",
      isDisable: false,
      modalImport: false,
      isLoading: false,
      modalShow: false,
      id: this.$route.params.id,
      isBusy: false,
      items: [],
      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "w-2",
        },
        {
          key: "total_product",
          label: "Total Product",
          thClass: "w-2",
        },
        {
          key: "description",
          label: "Description",
          thClass: "w-2",
        },
        {     key: "created_time",
          label: "Created Time",
          thClass: "w-2",
        },
        {
          key: "action",
          label: "",
          thClass: "w-1",
        },
      
        // {
        //   key: "create_by",
        //   label: "สร้างโดย",
        //   thClass: "w-2",
        // },
        // {
     
        // {
        //   key: "start_date",
        //   label: "Satrt Date",
        //   thClass: "w-2",
        // },
        // {
        //   key: "end_date",
        //   label: "End Date",
        //   thClass: "w-2",
        // },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
        product_id: Number(this.$route.params.id),
      },
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      collectionList: [],
      selectedItem: [],
      confirmMsg: "",
      formUpdate: {
        id: this.$route.params.id,
        select_collection: [],
      },
      allItem:[],
      rowsFormData: [],
      filteredItems: [],
    };
  },
  async created() {
    await this.getCollection();
  },
  methods: {
    async getCollection() {
      this.isBusy = true;
      const res = await this.axios.post(
        `/productmenu/GetCollectionByProductId/${this.id}`,
        this.filter
      );
      this.rows = res.data.detail.count;
      this.allItem = res.data.detail.data;
      this.items =  res.data.detail.data;
      this.filteredItems = [...this.allItem];
      // this.getDropdownCollection();
      this.selectedItem = this.items;
      this.formUpdate.select_collection = this.selectedItem.map((x) => x.id);
      this.isBusy = false;
    },

    async getDropdownCollection() {
      const res = await this.axios(`/ProductMenu/get_dropdown_collection_list`);
      this.collectionList = res.data.detail;
    },
    openCollectionDetail(id) {
      this.$router.push(`/product-collection/detail/${id}`);
    },
    openModalSelect() {
      this.$refs.modalSelectCollection.show(this.items);
    },
    handleSelectCollection(items) {
      if (items) {
        this.allItem = items;
      }

      this.isBusy = true;
      const maxItems = this.filter.take;
      if (this.allItem.length > maxItems) {
        this.items = this.allItem.slice(0, maxItems);
      } else {
        this.items = this.allItem;
      }
      this.rows = items.length;
      this.selectedItem = items;
      this.filteredItems = items
      this.isBusy = false;

    },
    async deleteCollection() {
        this.allItem = this.allItem.filter((x) => x.id !== this.deleteId);
        this.filteredItems = this.filteredItems.filter(
          (x) => x.id !== this.deleteId
        );
        this.selectedItem = this.selectedItem.filter(
          (x) => x.id !== this.deleteId
        );
        this.filter.page = 1;
        this.updateDisplayedItems();
    },
    openModalDelete(id) {
      this.deleteId = id;
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteCollection();
        }
      });
      this.deleteId= id;
    },
    handleSearch() {
      const searchText = this.filter.search.toLowerCase();
      console.log(searchText);
      if (searchText) {
        this.filteredItems = this.allItem.filter((item) =>
          item.name.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems() {
      this.isBusy = true;
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;

      this.isBusy = false;
    },
  },
  watch: {
    selectedItem(newValue) {
      this.formUpdate.select_collection = newValue.map((x) => x.id);
      this.$emit("update", this.formUpdate);
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-collection {
  width: 50% !important;
}

.icon-size {
  font-size: 20px;
  color: #575757;
}
</style>
