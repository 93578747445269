<template>
  <div class="bg-white p-2 p-lg-3">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="row">
        <b-col class="col-md-6 col-8">
          <b-input-group class="mb-2">
            <b-form-input
              type="text"
              id="header-search-bar"
              class="search-bar"
              @keyup.enter="handleSearch"
              placeholder="Search Serial No, Branch Name"
              v-model="filter.search"
            >
            </b-form-input>
            <b-input-group-append is-text>
              <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="text-right col-md-6">
          <b-button class="btn-filter mr-2" @click="importFile()">
            <font-awesome-icon icon="file-upload" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="file-upload" class="pointer" />
              <span class="btn-text">Import</span>
            </span>
          </b-button>
          <b-button class="btn-filter mr-2" @click="openManage()">
            <font-awesome-icon icon="plus-square" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="plus-square" class="pointer" />
              <span class="btn-text">Create New</span>
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(serial)="{ item }">
          <div class="name-link" @click="openManage(item)">
            {{ item.serial }}
          </div>
        </template>
        <template v-slot:cell(branch_name)="{ item }">
          <router-link
            :to="`/branch-management/detail/${item.branch_id}`"
            class="name-link"
          >
            {{ item.branch_name }}
          </router-link>
        </template>
        <template v-slot:cell(price)="{ item }">
          <p class="m-0 price-text" v-if="item.price || item.price == 0">
            {{ item.price | numeral("0,0.00") }}
          </p>
          <p v-else class="m-0 price-text">-</p>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(action)="{ item }">
          <div class="d-flex justify-content-center">
            <div @click="openModalDelete(item.id)">
              <b-icon size="xl" icon="trash-fill" class="icon-size pointer" />
            </div>
          </div>
        </template>
      </b-table>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
      <ModalManageSerial
        ref="modalmanageSerial"
        :branchList="branchList"
        @success="getProductSerial"
      ></ModalManageSerial>
      <b-modal
        v-model="modalImport"
        no-close-on-backdrop
        centered
        title="Import Product"
        hide-footer
      >
        <div>
          <b-row class="no-gutters">
            <b-col md="12">
              <UploadFileV2
                textFloat="File"
                placeholder="Please Choose File"
                format="excel"
                name="file"
                text="*Please upload only file .xlsx less than 10 MB"
                isRequired
                v-on:onFileChange="onFileChange"
                v-on:delete="deleteFile"
                :fileName="form.file_name"
                v-model="form.excel_file"
                :v="$v.form.excel_file"
                id="uploadfile"
              />
            </b-col>
            <b-col cols="12">
              <InputText
                textFloat="Email"
                placeholder="Email"
                type="text"
                name="email"
                isRequired
                v-model="form.email"
                :v="$v.form.email"
                :isValidate="$v.form.email.$error"
            /></b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-button
                size="md"
                class="btn-cancel"
                @click="modalImport = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col class="text-right">
              <b-button
                id="upload"
                class="main-color btn-save color-btn"
                size="md"
                :disabled="isDisable"
                @click="importFiles"
                >Upload</b-button
              >
            </b-col>
          </b-row>
        </div>
      </b-modal>

    </div>
  </div>
</template>
<script>
import ModalManageSerial from "./ModalManageSerial.vue";
import { required, email } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: {
    ModalManageSerial,
    OtherLoading,
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        excel_file: {
          required,
        },
      },
    };
  },
  data() {
    return {
      form: {
        email: "",
        file_name: "",
        excel_file: "",
      },
      product_id: Number(this.$route.params.id),
      isDisable: false,
      modalImport: false,
      isLoading: false,
      modalShow: false,
      id: this.$route.params.id,
      isBusy: false,
      items: [],
      fields: [
        {
          key: "branch_name",
          label: "Branch Name",
          thClass: "w-2",
        },
        {
          key: "branch_short",
          label: "Branch Short-name",
          thClass: "w-2",
        },
        {
          key: "serial",
          label: "Serial",
          thClass: "w-2",
        },
        {
          key: "price",
          label: "Price",
          thClass: "w-2",
        },
        {
          key: "action",
          label: "Action",
          thClass: "w-1",
        },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
        product_id: Number(this.$route.params.id),
      },
      confirmMsg: "",
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      branchList: [],
      serail_detail: "",
    };
  },
  created(){
this.getProductSerial()
  },
  methods: {
    async openManage(items) {
      this.$refs.modalmanageSerial.show(items);
    },

    handleSearch() {
      this.filter.page = 1;
      this.getProductSerial();
      
    },

    async getProductSerial() {
      this.isBusy = true;
      if(this.branchList.length == 0){
      await this.getBranchList();
      }
      const res = await this.axios.post(
        `/productmenu/get_product_serial/${this.id}`,
        this.filter
      );
      this.rows = res.data.detail.count;
      this.items = res.data.detail.data.map((x) => {
        return {
          ...x,
          branch_short: this.branchList.find((y) => y.id === x.branch_id)
            ?.short_name,
        };
      });
      this.isFetch = true;
      this.isBusy = false;
    },
    async getBranchList() {
      const res = await this.axios(`/branch/listAll`);
      if (res.data.result == 1) {
        this.branchList = res.data.detail;
      }
    },
    pagination(val) {
      this.filter.page = val;
      this.getProductSerial();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.getProductSerial();
    },

    importFile() {
      this.form = {
        email: this.$cookies.get("back_office_admin_email"),
        file_name: "",
        excel_file: "",
      };
      this.$v.form.$reset();
      this.modalImport = true;
    },
    async importFiles() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(
        `/import/importexcelproductserial/${this.product_id}`,
        this.form
      );

      this.$bus.$emit("hideLoading");
      if (res.data.result) {
        this.modalImport = false;
        this.successAlert();
        this.filter.page = 1;
        this.getProductSerial();
      } else {
        this.errorAlert(res.data.message);
      }
    },
    onFileChange(file) {
      this.form.file_name = file.name;
      this.isDisable = true;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
      this.isDisable = false;
    },
    deleteFile() {
      this.form = {
        file_name: "",
        excel_file: "",
      };
    },
    openModalDelete(id) {
      this.deleteId = id;
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteSerial();
        }
      });
      this.deleteId = id;
    },
    async deleteSerial() {
      this.isLoading = true;

      const res = await this.axios.delete(
        `/productmenu/delete_product_serial/${this.deleteId}`
      );
      if (res.data.result == 1) {
        this.successAlert();
        this.filter.page = 1;
        this.getProductSerial();
      } else {
        this.errorAlert(res.data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scope lang="scss">
.icon-size {
  font-size: 20px;
  color: #575757;
}
</style>
