<template>
  <div>
    <b-button v-b-toggle.collapse-images class="collapse-btn content-between">
      <span class="img-note label">
        Images Support Only .jpg, .png for image (Maximum 10 MB)</span
      >
      <font-awesome-icon
        icon="chevron-down"
        class="icon float-right mt-1 when-opened"
      />
      <font-awesome-icon
        icon="chevron-left"
        class="icon float-right mt-1 when-closed"
      />
    </b-button>
    <b-collapse id="collapse-images" visible>
      <div class="thumbnailGroup p-3">
        <MultipleImages
          @updateImageList="updateImages"
          :dataList="form.custom_field.additional_images"
          :v="v.imageList"
          v-on:deleteImagesList="deleteImages"
        />
        <div>

        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import MultipleImages from "./MultipleImages.vue";
export default {
  components: {
    MultipleImages,
  },
  props: {
    items: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
  },
  computed: {
    form() {
      return this.items;
    },
  },
  methods: {
    deleteImages(id) {
      this.$emit("deleteImages", id);
    },
    updateImages() {
      this.$emit("updateImageList");
    },
  },
};
</script>

<style scoped>
.thumbnailGroup {
  padding-bottom: 0px !important;
  border-top: 0px;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
}
.img-note {
  font-size: 12px;
  margin-left: 5px;
  width: 300px;
  display: contents;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 4px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
