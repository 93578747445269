import { render, staticRenderFns } from "./ProductCustomField.vue?vue&type=template&id=70ab58de&scoped=true"
import script from "./ProductCustomField.vue?vue&type=script&lang=js"
export * from "./ProductCustomField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ab58de",
  null
  
)

export default component.exports